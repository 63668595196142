import { createRouter, createWebHashHistory } from 'vue-router'

import DashboardView from '../views/DashboardView.vue'
import ProfileView from '@/views/ProfileView.vue'
import PropertiesView from '@/views/PropertiesView.vue'
import CustomersView from '@/views/CustomersView.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/properties',
    name: 'properties',
    component: PropertiesView
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
