<template>

<div class="flex min-h-screen">
    <SideBarComponent class="hidden" />
    <div class="flex-1 flex flex-col">
      <NavBarComponent />
      <div class="flex-1 p-6">
        <router-view/>        
      </div>
    </div>
</div>

 
</template>

<script>
import SideBarComponent from './components/SideBarComponent.vue';
import NavBarComponent from './components/NavBarComponent.vue';


export default {
  components: { 
    SideBarComponent, 
    NavBarComponent 
    },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    
  },
};
</script>

<style scoped>
/* Estilos adicionales si son necesarios */
</style>
