
<template>    
    <div class="w-full rounded overflow-hidden shadow-lg h-full " >
        <div class="p-2 flex flex-col">
            <div class="flex flex-1 justify-center items-center">
                <fwb-avatar alt="Alternative text" size="xl" :img="avatarUrl" />
            </div>
            
            <div class="flex flex-1 justify-center items-center hidden">
                <fwb-file-input v-model="file" label="Haz clic para subir tu foto de perfil" />
            </div>
            
        </div>

        <div class="p-2 flex flex-col">
            <div class="flex flex-1 justify-center items-center mt-5">
                <fwb-input v-model="facebook_url" label="Facebook url" placeholder="Facebook url" size="sm" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5">
                <fwb-input v-model="x_url" label="X url" placeholder="X url" size="sm" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5">
                <fwb-input v-model="instagram_url" label="Instagram url" placeholder="Instagram url" size="sm" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5">
                <fwb-input v-model="linkedin_url" label="Linkedin url" placeholder="Linkedin url" size="sm" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5">
                <fwb-input v-model="web_url" label="Web url" placeholder="Web url" size="sm" class=" w-full" />
            </div>
        </div>

        <div class="p-2 flex flex-col">
            <div class="flex flex-1 justify-end items-end mt-5 p-2">
                <fwb-button  class="bg-tuin-100 hover:bg-tuin-100" size="xl">Guardar </fwb-button>
            </div>
        </div>
    </div>    
</template>

<script setup>
    import { FwbAvatar, FwbFileInput, FwbInput, FwbButton } from 'flowbite-vue'
    import { ref } from 'vue'
    
    const avatarUrl = require('@/assets/imgs/avatar_default.png');
    const file = ref(null)
    
    let facebook_url    = ref(null)
    let x_url           = ref(null)
    let instagram_url   = ref(null)
    let linkedin_url    = ref(null)
    let web_url         = ref(null)
</script>