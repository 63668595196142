<template>
    <div class="w-full rounded overflow-hidden shadow-lg h-full">
        <div class="p-2 flex flex-col-2">
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="name" label="Nombre" placeholder="Ingresa tu nombre" size="sm" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <div placeholder="Ingresa tu apellido materno" class="w-full">
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Fecha de nacimiento</label>
                    <div class="flex relative">                        
                        <VueDatePicker v-model="date" class="" :format="format"></VueDatePicker>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-2 flex flex-col-2">
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="last_name" label="Apellido paterno" placeholder="Ingresa tu apellido paterno" size="sm" class=" w-full" />
            </div>
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="second_last_name" label="Apellido materno" placeholder="Ingresa tu apellido materno" size="sm" class=" w-full" />
            </div>
        </div>

        <div class="p-2 flex flex-col-2">
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="email" required placeholder="Ingresa tu email" label="Email" class=" w-full" />
            </div>

            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="company" label="Compañía" placeholder="Compañía" size="sm" class=" w-full" disabled />
            </div>
        </div>

        <div class="p-2 flex flex-col-2">
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-input v-model="phone" required placeholder="Ingresa tu teléfono" label="Teléfono" class=" w-full" />
            </div>

        </div>

        <div class="p-2 flex flex-col">
            <div class="flex flex-1 justify-center items-center mt-5 p-2">
                <fwb-textarea v-model="aboute_me" :rows="4" :cols="100" label="Sobre mí" placeholder="Escríbe una descripción sobre ti para que te conozcan más tus prospectos " class=" w-full" />
            </div>
        </div>

        
        <div class="p-2 flex flex-col">
            <div class="flex flex-1 justify-end items-end mt-5 p-2">
                <fwb-button  class="bg-tuin-100 hover:bg-tuin-100" size="xl">Guardar </fwb-button>
            </div>
        </div>
    </div>    
</template>

<script setup>
    import {  FwbInput, FwbTextarea, FwbButton } from 'flowbite-vue'
    import { ref } from 'vue'
    import VueDatePicker from '@vuepic/vue-datepicker';

    let name                = ref(null);
    let date                = ref();
    let email               = ref();
    let aboute_me           = ref();
    let phone               = ref();
    let company             = ref();
    let last_name           = ref();
    let second_last_name    = ref();

    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
        }
</script>
