<template>
    
    <span  v-if="type_movement_id == 1"
    class=" flex bg-tuin-100 text-white justify-center items-center
         text-base font-semibold rounded-sm w-24 px-2 py-1 z-50 absolute mt-2 ml-2">
      {{ label }}
    </span>

    <span  v-if="type_movement_id == 2"
    class=" flex bg-tuin_rent-50 text-white justify-center items-center
         text-base font-semibold rounded-sm w-24 px-2 py-1 z-50 absolute mt-2 ml-2">
      {{ label }}
    </span>
  </template>
  
  <script>
  export default {
    props: {
      label: {
        type: String,
        required: true,
      },
      type_movement_id: {
        type: Number
      }
    },
  };
  </script>
  