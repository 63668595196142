<template>
  
  <aside class="bg-black text-white w-64 min-h-screen hidden sm:block ">
    <ul class="space-y-4 p-4">
      <li>
        <img src="@/assets/imgs/LogoTuin.svg" > Asesor
      </li>
      <!--  -->
      <!--  -->
      <li>
        <router-link to="/dashboard" 
        @click="change_path('dashboard')"        
        class="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded "  
        :class="{ 'bg-tuin-100': current_path=='dashboard' }"
        >
        Dashboard 
        </router-link>        
      </li>


      <li>
        <router-link to="/profile" 
        @click="change_path('profile')"
        class="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded" 
        :class="{ 'bg-tuin-100': current_path=='profile' }"
        >
        Perfil
        </router-link>        
      </li>

      <li>
        <router-link to="/properties"
        @click="change_path('properties')"  
        class="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded" 
        :class="{ 'bg-tuin-100': current_path=='properties' }"
        >
        Propiedades
        </router-link>        
      </li>

      <li>
        <router-link to="/customers" 
        @click="change_path('customers')"  
        class="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded" 
        :class="{ 'bg-tuin-100': current_path=='customers' }"
        >
        Prospectos
        </router-link>        
      </li>

    </ul>
  </aside>
</template>

<script>


export default {
 
  name: 'SideBarComponent',  
  data() {
    return {
      current_path:'dashboard'
    };
  },
  methods: {
    change_path(path_link){
      this.current_path = path_link;
    }
  }
};
</script>

<style scoped>
/* Añade tus estilos personalizados aquí */
</style>
