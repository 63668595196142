import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../node_modules/flowbite-vue/dist/index.css'
/* or in your `app.css` file */
import './assets/app.css';
import VueApexCharts from "vue3-apexcharts";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import PrimeVue from 'primevue/config';
// import Lara from '@primevue/themes/lara';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueApexCharts);
app.use(PrimeVue, {
    // theme: {
    //     preset: Lara
    // }
});
app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
