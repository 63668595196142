<template>
  <div class="container mx-auto mt-10">
    <div class="flex flex-wrap ">
        <!-- filtro  -->
        <div class="flex flex-1">  
            <fwb-input v-model="searchQuery"  
            placeholder="Ingresa nombre de propiedad" 
            @input="filterProperties" 
            class=" w-full">
                <template #prefix>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
                </template>
            </fwb-input>
        </div>    
        <!-- filtro  -->

        <!-- <div class="flex flex-1 pl-3 hidden">  
            <fwb-button class=" bg-tuin-100 hover:bg-tuin-100">Buscar</fwb-button>
        </div> -->
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 mt-5">
      <fwb-card
        v-for="(item, index) in properties"
        :key="index"
        img-alt="Desk"
        variant="image"
        class="border-l-2"
      >
      
        <div class="w-full h-52 overflow-hidden">
            <LabelTag :label="item.type_movement_name" :type_movement_id="item.type_movement_id" />
            <div>
                <img :src="item.image" alt="item.property_name" class="w-full object-cover h-150" />
            </div>
        </div>
        <div class="p-5">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" >
            {{ item.property_name }}
          </h5>

          <p class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white" >
            {{ item.property_address }}
          </p>
          
          <div class=" text-tuin-100 text-2xl font-bold" v-show="item.type_movement_id==1">
            {{ item.property_value }}  <span class=" text-sm">{{ item.currency }} </span>
          </div>
          <div class=" text-tuin-100 text-2xl font-bold" v-show="item.type_movement_id==2">
            {{ item.rent_value }} <span class=" text-sm">{{ item.currency }} </span>
          </div>

          <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4  mt-4">
            <div class=" text-sm cursor-pointer"> 
                <fwb-tooltip>
                    <template #trigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 640 512" class=" float-left text-blue-400" >
                            <path fill="currentColor" d="M144 0a80 80 0 1 1 0 160a80 80 0 1 1 0-160m368 0a80 80 0 1 1 0 160a80 80 0 1 1 0-160M0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96H21.3C9.6 320 0 310.4 0 298.7M405.3 320h-.7c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7c58.9 0 106.7 47.8 106.7 106.7c0 11.8-9.6 21.3-21.3 21.3H405.4zM224 224a96 96 0 1 1 192 0a96 96 0 1 1-192 0m-96 261.3c0-73.6 59.7-133.3 133.3-133.3h117.3c73.7 0 133.4 59.7 133.4 133.3c0 14.7-11.9 26.7-26.7 26.7H154.6c-14.7 0-26.7-11.9-26.7-26.7z" />
                        </svg> <strong class="ml-2">10</strong> 
                    </template>
                    <template #content>
                        Prospectos
                    </template>
                </fwb-tooltip>

            </div>

            <div class=" text-sm cursor-pointer" > 

                <fwb-tooltip>
                    <template #trigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 48 48" class=" float-left text-lime-700">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M24.04 42.5c10.215 0 18.46-8.285 18.46-18.54c0-10.215-8.245-18.46-18.46-18.46C13.785 5.5 5.5 13.745 5.5 23.96c0 10.255 8.285 18.54 18.54 18.54m16.012-27.75H7.96m32.195 18.275H7.857M5.6 24h36.8M24.04 5.5v37" />
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M24.02 42.5c5.108 0 9.23-8.285 9.23-18.54c0-10.215-4.122-18.46-9.23-18.46c-5.128 0-9.27 8.245-9.27 18.46c0 10.255 4.142 18.54 9.27 18.54" />
                        </svg>
                        <strong class="ml-2">10</strong> 
                    </template>
                    <template #content>
                        Visitas
                    </template>
                </fwb-tooltip>    
            </div>
          
            <div class=" text-sm cursor-pointer"> 
                <fwb-tooltip>
                    <template #trigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 48 48" class=" float-left text-black ">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m15.107 20.294l2.957-2.957a4.9 4.9 0 0 0 1.244-4.87a24 24 0 0 1-.881-4.384C18.27 6.6 16.977 5.5 15.488 5.5H8.58c-1.777 0-3.145 1.535-2.989 3.304c1.575 17.829 15.777 32.03 33.606 33.606c1.77.156 3.304-1.207 3.304-2.984v-6.16c0-2.248-1.102-3.536-2.583-3.693a24 24 0 0 1-4.384-.88a4.9 4.9 0 0 0-4.87 1.243l-2.957 2.957" />
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M11.393 5.5c0 2.627.325 5.179.938 7.616C15.728 26.614 27.947 36.608 42.5 36.608" />
                        </svg>
                        <strong class="ml-2">10</strong> 
                    </template>
                    <template #content>
                        Clics llamada
                    </template>
                </fwb-tooltip>
            </div>

            <div class=" text-sm cursor-pointer">
                <fwb-tooltip>
                    <template #trigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"  viewBox="0 0 256 258" class=" float-left ">
                            <defs>
                                <linearGradient id="logosWhatsappIcon0" x1="50%" x2="50%" y1="100%" y2="0%">
                                    <stop offset="0%" stop-color="#1faf38" />
                                    <stop offset="100%" stop-color="#60d669" />
                                </linearGradient>
                                <linearGradient id="logosWhatsappIcon1" x1="50%" x2="50%" y1="100%" y2="0%">
                                    <stop offset="0%" stop-color="#f9f9f9" />
                                    <stop offset="100%" stop-color="#fff" />
                                </linearGradient>
                            </defs>
                            <path fill="url(#logosWhatsappIcon0)" d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a123 123 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004" />
                            <path fill="url(#logosWhatsappIcon1)" d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z" />
                            <path fill="#fff" d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561s11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716s-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64" />
                        </svg>
                        <strong class="ml-2">10</strong> 
                    </template>
                    <template #content>
                        Clics en whatsapp
                    </template>
                </fwb-tooltip>                 
            </div>
          </div>

          <div class=" flex flex-wrap  justify-end items-end mt-5">
            <div class=" flex flex-col">                
                <fwb-tooltip>
                    <template #trigger>
                        <fwb-button  class=" bg-tuin-100" size="xs"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 2048 2048">
                                <path fill="currentColor" d="M1280 896q-80 0-149 30t-122 82t-83 123t-30 149q0 92-41 173t-115 136q65 33 117 81t90 108t57 128t20 142H896q0-79-30-149t-82-122t-123-83t-149-30q-80 0-149 30t-122 82t-83 123t-30 149H0q0-73 20-141t57-128t89-108t118-82q-74-55-115-136t-41-173q0-79 30-149t82-122t122-83t150-30q92 0 173 41t136 115q38-75 97-134t134-97q-74-55-115-136t-41-173q0-79 30-149t82-122t122-83t150-30q79 0 149 30t122 82t83 123t30 149q0 92-41 173t-115 136q65 33 117 81t90 108t57 128t20 142h-128q0-79-30-149t-82-122t-123-83t-149-30m-256-384q0 53 20 99t55 82t81 55t100 20q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100M512 1536q53 0 99-20t82-55t55-81t20-100q0-53-20-99t-55-82t-81-55t-100-20q-53 0-99 20t-82 55t-55 81t-20 100q0 53 20 99t55 82t81 55t100 20m1536 256h-256v256h-128v-256h-256v-128h256v-256h128v256h256z" />
                            </svg>
                        </fwb-button>
                    </template>
                    <template #content>
                        Asignar prospecto
                    </template>
                </fwb-tooltip>

            </div>

            <div class=" flex flex-col ml-2">
                
                <fwb-tooltip>
                    <template #trigger>
                        <fwb-button class="bg-tuin-100 flex items-center justify-center p-2" size="xs" :href="item.slug_url" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 48 48">
                                <defs>
                                <mask id="ipTViewGridDetail0">
                                    <g fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="4">
                                    <rect width="36" height="36" x="6" y="6" fill="#555" rx="3" />
                                    <path fill="#555" d="M13 13h8v8h-8z" />
                                    <path stroke-linecap="round" d="M27 13h8m-8 7h8m-22 8h22m-22 7h22" />
                                    </g>
                                </mask>
                                </defs>
                                <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipTViewGridDetail0)" />
                            </svg>
                        </fwb-button>
                    </template>
                    <template #content>
                        Ver detalle propiedad
                    </template>
                </fwb-tooltip>

            </div>

            <div class=" flex flex-col ml-2">
                <fwb-tooltip>
                    <template #trigger>
                        <fwb-button  class=" bg-tuin-100" size="xs"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" >
                                <path fill="currentColor" d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006z" />
                                <path fill="currentColor" d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2" />
                            </svg>
                        </fwb-button>
                    </template>
                    <template #content>
                    Editar
                    </template>
                </fwb-tooltip>
            </div>

          </div>
        </div>
      </fwb-card>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { FwbCard, FwbInput, FwbButton, FwbTooltip } from "flowbite-vue";
import LabelTag from '@/commond/LabelTag.vue'

// import { mapState, mapActions } from 'vuex';
// ...mapActions(['saveUserSession', 'loadUserSession', 'clearUserSession']),
// import { FilterMatchMode, FilterOperator } from '@primevue/core/api';

// const filters = ref();

// const loading = ref(true);

let properties = ref([]);
let filteredProperties = ref([]);

// let error = ref({});
let searchQuery = ref(null)

onMounted(() => {
  get_propierties();
});


async function get_propierties() {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/catalogue/properties`
    );

    if (response.status == 401) {
      console.log("Hay que sacarlo de la sesion ");
    }

    if (response.status == 200) {
      properties.value = response.data.resp;
      filteredProperties.value = response.data.resp;
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function filterProperties() {
      const query = searchQuery.value.toLowerCase();
      
      properties.value = filteredProperties.value.filter(property => {
        return Object.keys(property).some(key => {            
          if (typeof property[key] === 'string') {
            return property[key].toLowerCase().includes(query);
          }
          return false;
        });
      });
}

</script>
