<template>
  <nav class="bg-black text-white shadow">
    <div class="container mx-auto px-4 py-2 flex justify-between items-center">
      <div class="text-xl font-bold">
        <div class="hidden sm:block"> &nbsp;</div>
        <!-- Vista mobile  falta poner un botón para que abra el menu-->
        <div class="flex flex-col-3 block sm:hidden">
          <div class="flex flex-col justify-start items-start">
            <img src="@/assets/imgs/LogoTuin.svg" >
          </div>  
          <div class="flex flex-col justify-center items-center ml-2">
            Asesor
          </div>  

        </div>
        <!-- Vista mobile -->
        
      </div>

      <div class="text-xl font-bold">

      </div>

      <div class="flex items-center space-x-4">
        
        <!-- <a href="#" class="text-gray-700 hover:text-gray-900">Account</a>
        <a href="#" class="text-gray-700 hover:text-gray-900">Inbox</a>
        <a href="#" class="text-gray-700 hover:text-gray-900">Taskboard</a>
        <a href="#" class="text-gray-700 hover:text-gray-900">Settings</a> -->
        <a href="#" class="text-white hover:text-red-600">Salir</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBarComponent',
};
</script>

<style scoped>
/* Añade tus estilos personalizados aquí */
</style>
